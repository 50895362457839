<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <div class="brand-logo auth-bg">
        <logo />
      </div>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 overflow-hidden bg-white background-no-repeat bg-cover"
        :style="`background-image: url(${imgUrl}); background-position: center;`"
      />
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-lg-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('auth.login.header') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('auth.login.text') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                :label="$t('auth.your-email')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">
                    {{ $t('auth.password') }}
                  </label>
                  <b-link :to="{name:'forgot-password'}">
                    <small>
                      {{ $t('auth.login.forgot-password-question') }}
                    </small>
                  </b-link>
                </div>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('auth.password')"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="current-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                      autocomplete="current-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!--              <b-form-group>-->
              <!--                <b-form-checkbox-->
              <!--                  id="remember-me"-->
              <!--                  v-model="status"-->
              <!--                  name="checkbox-1"-->
              <!--                >-->
              <!--                  {{ $t('auth.login.remember-me') }}-->
              <!--                </b-form-checkbox>-->
              <!--              </b-form-group>-->

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="processing"
                @click="login"
              >
                <b-spinner
                  v-show="processing"
                  small
                />
                {{ $t('auth.login.sign-in') }}
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>
              {{ $t('auth.login.new-user-question') }}
            </span>
            <b-link :to="{name:'register'}">
              <span>&nbsp;
                {{ $t('auth.login.create-account') }}
              </span>
            </b-link>
          </b-card-text>

          <!-- divider
          <div class="divider my-2">
            <div class="divider-text">
              {{ $t('auth.login.or') }}
            </div>
          </div>
          -->
          <!-- social buttons
          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div>-->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Logo from '@/views/_partials/Logo.vue'
import { email, required } from '@/libs/validations/validations'
import store from '@/store'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    Logo,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      processing: false,
      sideImg: require('@/assets/images/pages/lessons/lesson_01.jpg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        // this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      }
      return this.sideImg
    },
  },
  beforeMount() {
    this.isUserLoggedIn()
  },
  methods: {
    isUserLoggedIn() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$router.push({ name: 'dashboard-redirect' })
        return true
      }
      this.loading = false
      return false
    },
    login() {
      if (this.isUserLoggedIn()) {
        return
      }

      this.$refs.loginValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.processing = true
          const payload = {
            email: this.userEmail,
            password: this.password,
          }
          this.$store.dispatch('auth/login', payload)
            .then(this.getUserInfo)
            .catch(response => {
              window.toast.notify.danger(this.$t(response.message))
            })
            .finally(() => {
              this.processing = false
            })

          return true
        })
    },
    getUserInfo() {
      this.$store.dispatch('auth/fetchUserInfo')
        .then(() => {
          // redirect to previously visited page
          const prevRoute = localStorage.getItem('previousPath')
          if (prevRoute) {
            localStorage.removeItem('previousPath')
            this.$router.push(prevRoute)
          } else {
            this.$router.push({ name: 'dashboard-redirect' })
          }
        })
        .catch(err => {
          window.toast.notify.danger(err.message)
          this.processing = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper .brand-logo {
  border-radius: 0.357rem;
  img {
    width: 200px;
    height: inherit;
    margin: 30px;
  }
}
</style>
